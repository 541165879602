<template>
  <v-dialog v-model="dialog" width="500px" persistent>
    <template v-slot:activator="{ on: dialog, attrs }">
      <v-btn v-blur class="red white--text" v-bind="attrs" v-on="{ ...dialog }">
        Delete Label
      </v-btn>
    </template>
    <v-card>
      <v-card-title class="msaBlue white--text"> Delete Label </v-card-title>
      <v-card-text class="mt-2 text-body-1">
        Are you sure you want to delete this label? <br />
      </v-card-text>
      <v-card-actions>
        <v-row no-gutters>
          <v-col class="grow" align="right">
            <v-btn
              @click="dialog = false"
              plain
              class="msaBlue--text font-weight-bold"
            >
              Cancel
            </v-btn>
          </v-col>
          <v-col class="shrink" align="right">
            <v-btn @click="deleteLabel()" plain class="msaBlue white--text">
              OK
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: 'DeleteLabel',
  props: {
    labelId: {},
  },
  data() {
    return { dialog: false };
  },
  methods: {
    deleteLabel() {
      let url = 'delete-label?format=json';
      let params = {
        labelId: this.labelId,
        loaderText: 'Deleting...',
      };
      this.$axios
        .post(url, params)
        .then(() => {
          this.$router.replace({
            name: 'IALabels',
          });
        })
        .catch(error => {
          return error;
        });
      this.close();
    },
    close() {
      this.dialog = false;
    },
  },
  mounted() {},
};
</script>
<style scoped></style>
