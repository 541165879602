<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <v-card :key="reload">
          <v-card-title class="msaBlue white--text">
            {{ title }}
          </v-card-title>
          <v-form ref="form">
            <v-card-text class="py-0">
              <v-container class="pb-0 px-0" fluid>
                <v-row dense>
                  <v-col cols="12"> Name </v-col>
                  <v-col cols="12">
                    <v-text-field
                      class="py-0"
                      dense
                      required
                      counter
                      maxlength="1000"
                      outlined
                      v-model="label.name"
                      :rules="rules.name"
                      data-testid="label-name"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="12"> Description </v-col>
                  <v-col cols="12">
                    <v-textarea
                      class="py-0"
                      dense
                      height="60px"
                      counter
                      maxlength="2000"
                      outlined
                      v-model="label.description"
                      data-testid="label-description"
                    >
                    </v-textarea>
                  </v-col>
                  <v-col cols="12"> Group </v-col>
                  <v-col cols="12">
                    <CustomizedAutoComplete
                      :value="label.labelGroupId"
                      :items="labelGroups"
                      :rules="rules.required"
                      data-testid="label-group"
                      @change="$set(label, 'labelGroupId', $event)"
                    />
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
          </v-form>
          <v-card-actions class="pa-0">
            <v-container class="px-4" fluid>
              <v-row>
                <v-col align="left">
                  <DeleteLabel v-if="isEditMode" :labelId="label.id" />
                </v-col>
                <v-col align="right">
                  <v-btn
                    class="msaBlue white--text"
                    :disabled="disableSave"
                    @click="validate()"
                  >
                    Save
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import DeleteLabel from '@/components/Labels/DeleteLabel';
export default {
  components: {
    DeleteLabel,
  },
  data() {
    return {
      labelGroups: [],
      label: { id: -1, name: '', description: '' },
      original: {},
      rules: {
        name: [(v) => !!v.toString().trim() || 'This field is required.'],
        required: [(v) => !!v || 'This field is required'],
      },
      reload: false,
    };
  },
  computed: {
    disableSave() {
      return (
        JSON.stringify(this.label) === JSON.stringify(this.original) ||
        this.label.name.trim().length == 0 ||
        !this.label.labelGroupId
      );
    },
    isEditMode() {
      return this.label.id != -1;
    },
    title() {
      return this.$store.getters.selectedLabel.name;
    },
  },
  methods: {
    validate() {
      if (this.$refs.form.validate()) {
        this.save();
      }
    },
    save() {
      let url = 'save-label?format=json';
      let params = {
        label: this.label,
        loaderText: 'Saving...',
      };
      this.$axios
        .post(url, params)
        .then((response) => {
          if (this.$route.params.id == -1) {
            this.$router.replace({
              name: this.$route.name,
              params: { id: response.data.id },
            });
          }
          this.setData(response.data);
        })
        .catch((error) => {
          return error;
        });
    },
    setData(data) {
      this.$store.commit('updateSelectedLabel', data);
      this.label = data;
      this.original = JSON.parse(JSON.stringify(this.label));
    },
    getLabelGroups() {
      this.$axios.post('get-label-groups?format=json', {}).then((response) => {
        this.labelGroups = response.data;
      });
    },
    getLabel() {
      const params = {
        id: this.$route.params.id,
      };
      this.$axios.post('get-label?format=json', params).then((response) => {
        this.setData(response.data);
      });
    },
    loadUrlParams() {
      if (this.$route.params.id) {
        if (isNaN(parseInt(this.$route.params.id))) {
          this.$router.push({ name: 'Labels' });
        }
        if (parseInt(this.$route.params.id) > 0) {
          this.getLabel();
        } else {
          if (parseInt(this.$route.params.id) != -1) {
            this.$router.push({ name: 'Labels' });
          }
        }
      } else {
        this.$router.push({ name: 'Labels' });
      }
    },
  },
  beforeMount() {
    this.getLabelGroups();
    this.loadUrlParams();
  },
};
</script>
<style lang="scss" scoped>
.v-select::v-deep .v-chip {
  /* chip background color msaBlue */
  background-color: #3564ac;

  /* chip text color */
  color: white;

  /* chip X color */
  button {
    color: white;
  }
}
</style>
